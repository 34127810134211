import { TwitterType } from "./types";
import { useTwitter } from "./useTwitter";

export function TwitterLogin({
  children,
  redirectUri,
  clientId,
  onSuccess,
  onError,
  state,
  scope,
  closePopupMessage,
}: TwitterType) {
  const { twitterLogin } = useTwitter({
    redirectUri,
    clientId,
    onSuccess,
    onError,
    state,
    scope,
    closePopupMessage,
  });
  return children({ twitterLogin });
}
