import { useEffect, useState } from "react";
import {
  TWITTER_OAUTH2_STATE,
  TWITTER_OAUTH2_ERROR,
  TWITTER_OAUTH2_CODE,
  TWITTER_OAUTH2_RESULT,
  parse,
} from "./utils";

export function TwitterCallback({ redirect }) {
  const [errorMessage, setErrorMessage] = useState<string>("");
  useEffect(() => {
    const params = parse(window.location.search);
    localStorage.setItem(TWITTER_OAUTH2_RESULT, "1");
    localStorage.setItem(TWITTER_OAUTH2_CODE, "");
    localStorage.setItem(TWITTER_OAUTH2_ERROR, "");
    if (params.state !== localStorage.getItem(TWITTER_OAUTH2_STATE)) {
      localStorage.setItem(TWITTER_OAUTH2_ERROR, "State does not match");
    } else if (params.error) {
      const errorMessage =
        params.error_description || "Login failed. Please try again.";
      localStorage.setItem(TWITTER_OAUTH2_ERROR, errorMessage);
      window.location.href = redirect;
    }
    if (params.code) {
      localStorage.setItem(TWITTER_OAUTH2_CODE, params.code);
      window.location.href = redirect;
    }
  }, []);

  return <div>{errorMessage}</div>;
}
