import { useCallback, useEffect, useRef } from "react";
import { useTwitterType } from "./types";
import {
  TWITTER_OAUTH2_STATE,
  TWITTER_OAUTH2_CHANGE,
  TWITTER_OAUTH2_ERROR,
  TWITTER_OAUTH2_CODE,
  TWITTER_OAUTH2_RESULT,
  getPopupPositionProperties,
  generateRandomString,
} from "./utils";

export function useTwitter({
  redirectUri,
  clientId,
  onSuccess,
  onError,
  scope = "tweet.read tweet.write users.read offline.access",
  state = "",
  closePopupMessage = "User closed the popup",
}: useTwitterType) {
  const popupRefT = useRef<Window>();
  const popUpIntervalRefT = useRef<number>(null);

  useEffect(() => {
    if (
      localStorage.getItem(TWITTER_OAUTH2_RESULT) &&
      localStorage.getItem(TWITTER_OAUTH2_RESULT) == "1"
    ) {
      if (
        localStorage.getItem(TWITTER_OAUTH2_CODE) &&
        localStorage.getItem(TWITTER_OAUTH2_CODE) != ""
      ) {
        onSuccess &&
          onSuccess(
            localStorage.getItem(TWITTER_OAUTH2_CODE),
            localStorage.getItem(TWITTER_OAUTH2_CHANGE)
          );
      } else if (
        localStorage.getItem(TWITTER_OAUTH2_ERROR) &&
        localStorage.getItem(TWITTER_OAUTH2_ERROR) != ""
      ) {
        onError &&
          onError({
            error: " Error",
            errorMessage: localStorage.getItem(TWITTER_OAUTH2_ERROR),
          });
      }
    }

    localStorage.setItem(TWITTER_OAUTH2_RESULT, "0");
    localStorage.setItem(TWITTER_OAUTH2_CODE, "");
    localStorage.setItem(TWITTER_OAUTH2_ERROR, "");
  }, []);

  const getUrl = () => {
    const scopeParam = `&scope=${encodeURI(scope)}`;
    const generatedChange = state || generateRandomString();
    const generatedState = state || generateRandomString();
    localStorage.setItem(TWITTER_OAUTH2_STATE, generatedState);
    localStorage.setItem(TWITTER_OAUTH2_CHANGE, generatedChange);
    const twitterAuthLink = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${generatedState}&code_challenge=${generatedChange}&code_challenge_method=plain`;
    return twitterAuthLink;
  };

  const twitterLogin = () => {
    window.location.href = getUrl();
  };

  return {
    twitterLogin,
  };
}
